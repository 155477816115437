<script setup>
import VSummarizing from '@/components/VSummarizing'
import { useRouter } from 'vue-router'
import PaginationRefactor from '@/components/ui/pagination/PaginationRefactor'
import { useGetItem } from '@/use/Base/item/useGetItem'
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
const lots = reactive([])
const goSummarizingItem = (id) => {
  router.push({ name: 'bidder-my-auctions-protocol', params: { id } })
}

function copyLot (protocols) {
  protocols.forEach(protocol => {
    lots.push({
      protocol: protocol.id,
      id: protocol.lot.id,
      name: protocol.lot.name,
      date: [
        { title: 'Дата начала и время публикации', description: protocol.lot.published_at },
        { title: 'Дата и время начала ТП', description: protocol.lot.started_at },
        { title: 'Дата и время окончания ТП', description: protocol.lot.duration },
        {
          title: 'Статус',
          description: 'Торги состоялись',
          decorator: { name: 'Status' }
        }
      ]
    })
  })

  return lots
}

const configuration = reactive({
  urlSetRows: '/trade-offer/winner',
  requestIsAuth: true,
  urlActionQuery: 'expand=user,lot',
  sort: '-lot.duration'
})

const { handlerPage } = useGetItem(false, 9, configuration)

const getLots = computed(() => {
  return copyLot(store.getters['item/getItems'])
})

</script>

<template>
  <div class="grid" v-if="router.currentRoute.value.name === 'bidder-my-auctions-protocols'">
    <div>
      <div
        v-for="lot in getLots"
        :key="lot.id"
      >
        <VSummarizing
          :lot="lot"
          type="bidder"
          @to="goSummarizingItem"
        />
      </div>
    </div>

    <PaginationRefactor @page="handlerPage" />
  </div>

  <router-view></router-view>
</template>
