<script setup>
import { defineProps, defineEmits } from 'vue'
import VStatusInfo from '@/components/statusElements/VStatusInfo'
const emits = defineEmits(['to'])
const props = defineProps({
  type: {
    type: String,
    required: false,
    default: 'organizer'
  },
  lot: {
    type: Object,
    required: true
  }
})

const goSummarizingItem = () => {
  if (props.type === 'organizer') {
    emits('to', props.lot.id)
  } else {
    emits('to', props.lot.protocol)
  }
}

</script>

<template>
    <div class="grid mb-20 p-30 bg--light-gray">
      <div class="block__subtitle">
        {{ lot?.id }}
      </div>
      <div class="block__subtitle-small">
        {{ lot?.name }}
      </div>
      <VStatusInfo
        :info="lot.date"
        type="result"
      />
      <div class="buttons-wrap">
        <button
          type="button"
          class="btn btn-primary btn-primary--padding btn-bg-green"
          :disabled="!lot.bidderCounter && type === 'organizer'"
          @click="goSummarizingItem"
        >
          Подробнее
        </button>
      </div>
    </div>

    <div class="p-50 mb-30 center bg--red" v-if="!lot.bidderCounter && type === 'organizer'">
      <div class="block__subtitle-small">
        У данной торговой процедуры нет ни одного участника.
      </div>
  </div>
</template>
